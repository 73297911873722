<template>
  <div>
    <nav :class="{
      'bg-white': $route.path === '/shop',
      'border-b-[1px] border-b-[#81868B]': $route.path === '/shop',
    }" class="navbar top-[-8px] pt-[26px] navbar-expand-md fixed-top mt-[41px] py-4 px-6 md:px-16 border-b-[1px] border-b-[white]/10 flex justify-between" id="nav">
      <svg @click="toggleSidebarLeft" xmlns="http://www.w3.org/2000/svg" class="z-10" :fill="svgFillColor" width="26" height="20" viewBox="0 0 26 20">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M25.9163 10.0001C25.9163 9.30973 25.3567 8.75008 24.6663 8.75008H1.33301C0.642651 8.75008 0.0830078 9.30973 0.0830078 10.0001C0.0830078 10.6904 0.642651 11.2501 1.33301 11.2501H24.6663C25.3567 11.2501 25.9163 10.6904 25.9163 10.0001Z" :fill="svgFillColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M25.9163 1.66675C25.9163 0.976392 25.3567 0.416748 24.6663 0.416748H1.33301C0.642651 0.416748 0.0830078 0.976392 0.0830078 1.66675C0.0830078 2.3571 0.642651 2.91675 1.33301 2.91675H24.6663C25.3567 2.91675 25.9163 2.3571 25.9163 1.66675Z" :fill="svgFillColor"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M25.9163 18.3334C25.9163 17.6431 25.3567 17.0834 24.6663 17.0834H1.33301C0.642651 17.0834 0.0830078 17.6431 0.0830078 18.3334C0.0830078 19.0238 0.642651 19.5834 1.33301 19.5834H24.6663C25.3567 19.5834 25.9163 19.0238 25.9163 18.3334Z" :fill="svgFillColor"/>
      </svg>
        <router-link
          class="absolute flex justify-center w-full left-0"
          to="/"
        >
          <div class="group flex h-[40px] w-10  justify-center">
            <svg :fill="svgFillColor" width="18" height="39" viewBox="0 0 28 49" xmlns="http://www.w3.org/2000/svg" class="group-hover:hidden">
                <path d="M1.44756 49L0 20.7652L12.5318 0L15.7578 23.2551L28 37.1484L16.13 38.8913L1.44756 49Z"/>
            </svg>
            <h1 style="text-align: center;vertical-align: middle;" class="hidden logo-text transition ease-in-out absolute bebas-neue-font text-[1.8rem] group-hover:block mt-[5px]"
            :class="{
              'text-[#191B1E] group-hover:text-[#191B1E]' : this.$route.path === '/shop',
              'text-[white] group-hover:text-[white]' : this.$route.path != '/shop'
            }">ASIXTH</h1>
            
          </div>
        </router-link>
      <div class="flex items-center relative z-10">
        <router-link
          class="hidden md:block w-[70px] hover:text-[#212529] font-catarell"
          to="/shop"
          :class="textColorPrimary"
        >
          Shop All
        </router-link>

        <a class="ml-2 md:ml-6" href="#" @click="toggleSidebar">
          <svg width="28" height="28" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.1294 13.3319C19.8396 12.3993 21 10.5852 21 8.5C21 5.46243 18.5376 3 15.5 3C12.4624 3 10 5.46243 10 8.5C10 10.5852 11.1604 12.3993 12.8706 13.3319C8.3441 14.4991 5 18.609 5 23.5V26.5C5 28.433 6.567 30 8.5 30H22.5C24.433 30 26 28.433 26 26.5V23.5C26 18.609 22.6559 14.4991 18.1294 13.3319ZM11 8.5C11 6.01472 13.0147 4 15.5 4C17.9853 4 20 6.01472 20 8.5C20 10.9853 17.9853 13 15.5 13C13.0147 13 11 10.9853 11 8.5ZM15.5 14C10.2533 14 6 18.2533 6 23.5V26.5C6 27.8807 7.11929 29 8.5 29H22.5C23.8807 29 25 27.8807 25 26.5V23.5C25 18.2533 20.7467 14 15.5 14Z" :fill="svgFillColor"/>
          </svg>
        </a>
        <a class="ml-2 md:ml-6" href="#" @click="toggleNavbar();checkingCart()">
          <svg width="28" height="28" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5 24.5C17.9853 24.5 20 22.0376 20 19C20 15.9624 17.9853 13.5 15.5 13.5C13.0147 13.5 11 15.9624 11 19C11 22.0376 13.0147 24.5 15.5 24.5ZM15.5 23.5C17.2547 23.5 19 21.6823 19 19C19 16.3177 17.2547 14.5 15.5 14.5C13.7453 14.5 12 16.3177 12 19C12 21.6823 13.7453 23.5 15.5 23.5Z" :fill="svgFillColor"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.4948 9.72727C24.526 10.3628 26 12.2593 26 14.5V24.5C26 27.2614 23.7614 29.5 21 29.5H10C7.23858 29.5 5 27.2614 5 24.5V14.5C5 12.2593 6.47397 10.3628 8.50522 9.72727C8.64848 5.98768 11.7253 3 15.5 3C19.2747 3 22.3515 5.98768 22.4948 9.72727ZM9.51869 9.52287C9.67709 9.50774 9.83764 9.5 10 9.5H21C21.1624 9.5 21.3229 9.50774 21.4813 9.52287C21.2381 6.43222 18.6531 4 15.5 4C12.3469 4 9.76185 6.43222 9.51869 9.52287ZM25 14.5C25 12.2909 23.2091 10.5 21 10.5H10C7.79086 10.5 6 12.2909 6 14.5V24.5C6 26.7091 7.79086 28.5 10 28.5H21C23.2091 28.5 25 26.7091 25 24.5V14.5Z" :fill="svgFillColor"/>
          </svg>
        </a>
      </div>
    </nav>

    <SidebarPopupLeftDetail :isOpen="isSidebarLeftDetailOpen" @toggle="toggleSidebarLeftDetail">
      <div>
        <div class="absolute w-[80%] md:w-[75%] text-center top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white">
          <h1 class="text-xl md:text-2xl font-neuton font-medium" v-if="detailSelectedMenu.length && detailSelectedMenu[0].content">
            {{ detailSelectedMenu[0].content[0].title }}
          </h1>
          <p class="text-sm md:text-md font-catarell mt-3" v-if="detailSelectedMenu.length && detailSelectedMenu[0].content" v-html="detailSelectedMenu[0].content[0].desc"></p>

          <div class="mt-4">
            <Button :title="'Return back'" @toggle="hideDetailMenu" />
          </div>

        </div>
      </div>
    </SidebarPopupLeftDetail>

    <SidebarPopupLeft :isOpen="isSidebarLeftOpen" @toggle="toggleSidebarLeft">
      <div class="relative">
        <Accordion :items="itemsAccordion" @selectSubMenu="selectSubMenu"/>
        <p v-for="(item, index) in listMenus" :key="index" class="font-semibold cursor-pointer flex justify-between text-lg items-center border-b-[1px] border-b-[white] font-neuton mt-4 mb-2" @click="selectSubMenu(item)">
              {{ item.title }}
        </p>
        <div class="mt-10">
          <h1 class="font-neuton text-lg font-semibold">Search with &nbsp;<b class="font-neuo ">ASIXTH</b></h1>
          <p class="font-catarell text-sm">
            Quickly find what you are looking for
          </p>
          <InputFormAutoComplete
            :dataSet="dataSet"
            :placeholder="'Search here'"
            :typeInput="'text'"
            :values="searchTerm"
            @changeInput="handleChangeInputSearch"
          />
        </div>
        <div class="px-10 w-full fixed left-0 bottom-4">
          <div class="flex justify-between border-t-[1px] border-t-white pt-2">
            <p class="font-catarell text-sm">Contact</p>
            <p class="font-catarell text-sm">Careers</p>
            <p class="font-catarell text-sm">Autonomy</p>
            <p class="font-catarell text-sm">Blog</p>
            <p class="font-catarell text-sm">FAQs</p>
          </div>
        </div>
      </div>
    </SidebarPopupLeft>

    <SidebarPopupLogin v-if="isLogin" :isOpen="isSidebarOpen" @toggle="toggleSidebar" >
        <div>
          <h1 class="font-neuton text-2xl md:text-4xl font-medium mb-10">
              Hello, {{userName}}<br> 
          </h1>
          <router-link to="/profile" @click="toggleSidebar" class="font-catarell text-md md:text-xl text-white hover:underline">
              View My Account
          </router-link>
          <br>
          <router-link to="/" @click="toggleSidebar" class="font-catarell text-md md:text-xl text-white hover:underline">
              Logout
          </router-link>
        </div>
    </SidebarPopupLogin>

    <SidebarPopup v-else :isOpen="isSidebarOpen" @toggle="toggleSidebar" >
        <div>
          <LoginForm v-if="isAuthForm === 'login'" @showForm="showForm"/>
          <RegisterForm v-if="isAuthForm === 'register'" @showForm="showForm"/>
        </div>
    </SidebarPopup>

    <NavbarPopup :isOpen="isNavbarOpen" @toggle="toggleNavbar">
      <div v-if="isHaveItem">
        <table class="w-full">
          <thead class="text-white font-averia text-lg md:text-2xl font-light">
            <th class="py-4">
              Cart
            </th>
            <th>
              Size
            </th>
            <th>
              Quantity
            </th>
            <th>
              Price
            </th>
            <th>
              Total
            </th>
          </thead>
          <tbody class="text-sm md:text-lg">
            <tr v-for="(item, index) in cartItem" :key="index" class="border-y-[1px] border-y-white font-averia">
              <td class="py-4 w-[31%] md:w-auto leading-6	md:leading-9">{{ item.title }} <br> {{ item.type }} </td>
              <td class="py-4 text-[1.2rem]">{{ item.size }}</td>
              <td class="py-4 text-[1.2rem]">{{ item.quantity }}</td>
              <td class="py-4 text-[1.2rem]">${{ item.price }}</td>
              <td class="py-4 text-[1.2rem]">${{ item.price * item.quantity}}</td>
            </tr>
          </tbody>
        </table>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 pt-4">
          <div>
            <p class="font-catarell text-white text-sm md:text-lg mt-4">
              We do not ship outside of the United States at this time. <br>
              Unfortunately we are not able to ship to Guam or APO
            </p>
            <p class="font-catarell text-white text-sm md:text-lg mt-4">
              All prices are listed in USD.
            </p>
          </div>
          <div>
            <div class="flex justify-between items-center">
              <p class="font-catarell font-bold text-white text-md md:text-xl mt-4">
                Subtotal
              </p>

              <p class="font-catarell font-bold text-white text-xl md:text-2xl mt-4">
                ${{ cartItem.reduce((total, item) => total + (item.price * item.quantity), 0).toFixed(2) }}
              </p>

            </div>
            <button class="bg-white w-full mt-4 text-black px-12 py-3 text-md md:text-xl" @click="checkOut()">
              Checkout
            </button>
          </div>
        </div>
      </div>
      <div v-else >
        <p class="font-catarell text-white text-md md:text-xl mt-4">
          You have no Item in your cart, Please add one item to checkout
        </p>
      </div>
    </NavbarPopup>
  </div>
</template>

<script>
  import SidebarPopup from '@/components/Popup/Sidebar.vue';
  import SidebarPopupLogin from '@/components/Popup/SidebarLogin.vue';
  import SidebarPopupLeft from '@/components/Popup/SidebarLeft.vue';
  import SidebarPopupLeftDetail from '@/components/Popup/SidebarLeftPopup.vue';
  import NavbarPopup from '@/components/Popup/Navbar.vue';
  import LoginForm from '@/components/Auth/Login.vue';
  import RegisterForm from '@/components/Auth/Register.vue';
  import HamburgerIcon from '@/assets/icon/Menu.svg'
  import Accordion from '@/components/Accordion/AccordionMenu.vue';
  import InputFormAutoComplete from '@/components/Form/InputAutoCompleteV2.vue';
  import Button from '@/components/Button/Button.vue';
  import Axios from 'axios';
  import DummyProduct from '@/assets/img/product/dummy.png'

  export default {
    components: {
      SidebarPopup,
      SidebarPopupLeft,
      SidebarPopupLeftDetail,
      SidebarPopupLogin,
      NavbarPopup,
      Button,
      LoginForm,
      RegisterForm,
      InputFormAutoComplete,
      Accordion
    },
    computed: {
      svgFillColor() {
        if (this.$route.path === '/shop' || this.$route.path === '/shop/canceled' || this.$route.path === '/shop/success') {
          return '#191B1E';
        } else {
          return 'white';
        }
      },
      textColorPrimary() {
        if (this.$route.path === '/shop' || this.$route.path === '/shop/canceled' || this.$route.path === '/shop/success') {
          return 'text-[#191B1E]';
        } else {
          return 'text-white';
        }
      },
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll);
      this.isLoginCheck();
    },
    data() {
      return {
        logoColor : false,
        HamburgerIcon: HamburgerIcon,
        isHaveItem: true,
        isSidebarOpen: false,
        isSidebarLeftOpen: false,
        isSidebarLeftDetailOpen: false,
        isNavbarOpen: false,
        isLogin: false,
        isAuthForm: 'login',
        searchTerm: '',
        userName : 'test',
        detailSelectedMenu: [],
        isLogoHover:false,
        dataSet: [
          {
            id: 1,
            title: 'Massachusetts',
            value: 'Massachusetts'
          },
          {
            id: 2,
            title: 'Maryland',
            value: 'Maryland'
          }
        ],
        itemsAccordion: [
          { 
            title: "Shop", 
            content: [
              {
                title: 'Commons',
                link: '/',
              },
              {
                title: 'Heritage',
                link: '/',
              },
              {
                title: 'Fraction',
                link: '/',
              },
              {
                title: 'Pillar',
                link: '/',
              },
              {
                title: 'Purpose',
                link: '/',
              },
              {
                title: 'Shop All',
                link: '/shop',
              },
            ], 
            open: false 
          },
        ],
        listMenus: [
          { 
            title: "About", 
            content: [
              {
                title: 'About Us',
                desc: 'ASIXTH is building the new standard for quality in the coffee space. We are making it easier for consumers to differentiate high quality coffee. The name blends A + SIXTH together. Sixth being the extra sense we never really acknowledge. <br><br> We are creating experiences that allows you to convey the invisible emotions you feel when you’re at your best but yet struggle to convey them <br><br> We know how important it is for you to function at your optimal best, and with coffee being the first thing you consume in the morning, it’s very important that the quality and typology not only reflects but matches the output of the exceptional results you will produce in your daily affairs',
              },
            ], 
          },
          { 
            title: "Faces", 
            content: [
              {
                title: 'Coming Soon',
                desc: '',
              },
            ], 
          },
          { 
            title: "Typology", 
            content: [
              {
                title: 'Cultivating Typology',
                desc: 'Typology allows you to determine what coffee region, roast style, price, brew, and more for a unique coffee experience both inside and outside our brand',
              },
            ], 
          },
          { 
            title: "True Specialty", 
            content: [
              {
                title: 'True Specialty Coffee',
                desc: 'True Specialty is a consumer quality mark that evaluates and verifies that the coffee has achieved either Cup of Excellence status or equivalent.<br><br> True Specialty Coffee is coffee that has been graded meticulously, sourced intentionally, farmed sustainably, roasted precisely, and priced affordably ',
              },
            ], 
          }
        ],
        cartItem: [

        ],
        data_login : {

        },
      };

    },
    watch: {
      isSidebarOpen(newVal) {
        if (newVal) {
          document.body.style.overflow = "hidden";
        } else {
          document.body.style.overflow = "auto";
        }
      },
    },
    methods: {
      checkingCart(){
        if (sessionStorage.getItem('LogedIn') == 'true') {
          var userId = this.data_login.user.id
          Axios.get("/api/cartData",{params : { id : userId
          }}).then(response => {
            this.cartItem = response.data;
            // console.log(response.data);
            if (this.cartItem.length  === 0) {
              this.isHaveItem = false;
            } else{
              this.cartItem = this.cartItem.map(product => ({
                title: product.item.name,
                size: '10oz',
                type: product.item.type,
                price: parseFloat(product.item.price),
                quantity: parseFloat(product.quantity)
              }));
            }
            
          });
        } else{
          this.isHaveItem = false;
        }
        
      },
      checkOut(){
        const datas = {
          userId : this.data_login.user.id,
          Image : DummyProduct,
        };
        // console.log(userId);
        Axios.post("/api/checkOut",datas).then(response =>{
          // console.log(response);
          window.open(response.data.url);
          window.close();
        });
      },
      isLoginCheck(){
        // console.log('test');
        var status = '';
        if (sessionStorage.getItem('LogedIn') == null) {
            status = false;
        } else{
            status = sessionStorage.getItem('LogedIn');
            this.data_login = JSON.parse(sessionStorage.getItem('data_login'));
        }

        if (status != null && status == 'true') {
          this.isLogin = true;
          this.userName = this.data_login.user.first_name
        }

        // if (sessionStorage.getItem('data_login') != null) {
        //     status = false;
        // } else{
        //   var userData= JSON.parse(sessionStorage.getItem('data_login'));
        //   if (status != null) {
        //     if (status === 'true') {
        //       this.userName = userData.user.first_name;
        //       this.isLogin = true;
        //     }
        //   }
        // }

      },
      toggleNavbar() {
        this.isNavbarOpen = !this.isNavbarOpen;
      },
      toggleSidebar() {
        this.isSidebarOpen = !this.isSidebarOpen;
      },
      toggleSidebarLeft() {
        this.isSidebarLeftOpen = !this.isSidebarLeftOpen;
      },
      toggleSidebarLeftDetail() {
        this.isSidebarLeftDetailOpen = !this.isSidebarLeftDetailOpen;
      },
      showForm(value) {
        this.isAuthForm = value;
      },
      handleChangeInputSearch(e) {
        this.searchTerm = e
      },
      selectSubMenu(value) {
        this.detailSelectedMenu = [value]
        this.isSidebarLeftDetailOpen = true;
        this.isSidebarLeftOpen = false;
      },
      hideDetailMenu(){
        this.isSidebarLeftDetailOpen = false;
        this.isSidebarLeftOpen = true;
      },
      beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
      },
      handleScroll() {
        const scrollPosition = window.scrollY;
        const navbar = document.getElementById('nav');
        if (scrollPosition > 0) {
          navbar.classList.add('bg-black', 'bg-opacity-10', 'backdrop-blur-xl');
        } else {
          navbar.classList.remove('bg-black', 'bg-opacity-10', 'backdrop-blur-xl');
        }
      },
      toggleLogo(){
        this.isLogoHover = !this.isLogoHover;
      },
      removedLoginState(){
        if (sessionStorage.getItem('LogedIn') != null) {
          sessionStorage.removeItem('LogedIn')
        }
        if (sessionStorage.getItem('data_login')) {
          sessionStorage.removeItem('data_login')
        }
      }
    },
  }
</script>

<style>
  .navbar {
    transition: background-color 0.3s;
  }
</style>
