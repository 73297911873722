<template>
  <div>
    <!-- Overlay -->
    <div v-if="isOpen" class="z-[9999] fixed inset-0 bg-black bg-opacity-10 backdrop-blur-xl cursor-pointer" @click="toggleSidebar"></div>

    <div :class="{ 'translate-x-0': isOpen, 'translate-x-full': !isOpen }" class="z-[9999] fixed h-full w-full md:w-3/12 transition-transform duration-300 ease-in-out left-0">
      <div class="h-full text-white relative">
        <div class="bg-[#0D0D0E] bg-opacity-70 backdrop-blur-xl w-full h-full absolute" style="max-height: 100%; overflow-y: auto;">
          <div class="pt-16 md:pt-28 px-10 z-[9999999]">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: Boolean,
  },
  methods: {
    toggleSidebar() {
      this.$emit('toggle'); // Emit event toggle ketika tombol Toggle Sidebar ditekan
    },
  },
};
</script>

<style scoped>
.translate-x-0 {
  transform: translateX(0);
}

.translate-x-full {
  transform: translateX(-100%);
}
</style>
