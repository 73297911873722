<template>
  <button class="bg-white text-black px-12 py-3 font-catarell font-semibold text-md md:text-md" @click="handleClick">
    {{ title }}
  </button>
</template>

<script>
export default {
  props: {
    title: String,
  },
  methods: {
    handleClick() {
      this.$emit('toggle');
    },
  },
};
</script>

<style scoped></style>
