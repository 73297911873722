<template>
  <div>
    <!-- <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
      <div class="container">
        <a class="navbar-brand" href="#">EXPRESS.JS + VUE.JS</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <ul class="navbar-nav me-auto mb-2 mb-md-0">
            <li class="nav-item">
              <router-link :to="{ name: 'home' }" class="nav-link" aria-current="page">HOME</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'posts.index' }" class="nav-link">POSTS</router-link>
            </li>
          </ul>
          <form class="d-flex">
            <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
            <button class="btn btn-success" type="submit">Search</button>
          </form>
        </div>
      </div>
    </nav> -->
    <WebNavbar :key="color"/>
    <Navbar/>
    <!-- <WebMenu /> -->
    
    <router-view></router-view>

  </div>
</template>

<script>
import WebMenu from './components/WebMenu.vue';
import WebNavbar from './components/WebNavbar.vue';
import Navbar from './components/Navbar/Navbar.vue';

export default {
  components: {
    WebNavbar,
    WebMenu,
    Navbar,
    // Pagination,  
    // Navigation,
  },
  mounted(){
      if (sessionStorage.getItem('resend_countdown') != null && parseInt(sessionStorage.getItem('resend_countdown')) > 0){
      var intervalId = window.setInterval(function(){
        var countdown = parseInt(sessionStorage.getItem('resend_countdown'));
        countdown = countdown - 1;
        sessionStorage.setItem('resend_countdown',countdown);
        console.log('-1');
        if (countdown <= 0 || isNaN(countdown)) {
          clearInterval(intervalId);
          sessionStorage.removeItem('resend_countdown');
          sessionStorage.remove('counted');
        }
      }, 1000);
      sessionStorage.setItem('counted',true);
      if (parseInt(sessionStorage.getItem('resend_countdown')) <= 0) {
        sessionStorage.remove('counted');
      }
  }
}
    
}
</script>

<style>
body {
  background-color: rgb(43, 43, 43) !important;
}

.mt-custom {
  margin-top: 110px;
}
</style>