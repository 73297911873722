<template>
  <div class="relative inline-block text-left w-full">
    <div>
      <button @click="toggleDropdown" class="w-full inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-gray-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 bg-transparent border-[1px] border-white py-3 px-4 text-white placeholder:text-left placeholder:text-white placeholder:font-catarell placeholder:font-normal font-catarell w-full outline-0">
        {{country_code}}
        <svg :class="{ 'rotate-180': !isOpen }" class="ml-2 transition-transform duration-200" width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 15L12 9L18 15" stroke="#FFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>
    <div v-if="isOpen" @click="toggleDropdown" class="fixed inset-0 h-full w-full z-10"></div>
    <div v-if="isOpen" @click="toggleDropdown" class="z-20 absolute w-24 mt-2 shadow-lg border-[1px] border-white ring-1 bg-[black] ring-black ring-opacity-5">
      <slot></slot>
    </div>
  </div>
</template>


<script>
import 'feather-icons'; 

export default {
  props: [
    'country_code'
  ],
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>
