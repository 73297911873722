<template>
  <div>
    <h1 class="font-neuton text-2xl md:text-4xl font-normal">
      Confirm Your  Magic Code 
    </h1>
    <p class="font-catarell text-md md:text-[1.1vw] mt-4">
        We've texted a magic code to {{phoneNumber}} <br>
        Enter the code we've sent to your device to login or signup.
    </p>

    <div class="mt-4">
      <v-otp-input
        ref="otpInput"
        v-model:value="VOtpInput"
        input-classes="otp-input"
        separator="-"
        :num-inputs="4"
        :should-auto-focus="true"
        input-type="letter-numeric"
        :conditionalClass="['one', 'two', 'three', 'four']"
        :placeholder="['*', '*', '*', '*',]"
      />
    </div>

    <p class="font-catarell text-md md:text-xl mt-4 mb-4">
      Didn’t get the Code ? <a class="font-bold" @click="resendOTP">Send Again</a>
      <br>
      {{otpMessage}} 
    </p>

    <!-- <Button :title="'Verify Now'" @toggle="toggleSidebar"/> -->
    <Button :title="'Verify Now'" @click="getDataLogin()"/>
  </div>
</template>

<script>
import Button from '@/components/Button/Button.vue';
import VOtpInput from "vue3-otp-input";
import Axios from 'axios';

export default {
  props: {
    title: String,
  },
  components: {
    Button,
    VOtpInput
  },
  data() {
    return {
      otpMessage: "",
      valOtp: "",
      data_login : {

      }
    };
  },
  watch: {
    // this.countdown_resend()
  },
  methods: {
    toggleSidebar() {
      this.$emit('toggle');
    },
    getDataLogin() {
      this.data_login = JSON.parse(sessionStorage.getItem('data_login'));
      if (this.data_login.otp == this.VOtpInput) {
        this.toggleSidebar();
      } else{
        this.otpMessage = "You’ve enter wrong code , Resend Again"
      }
      // console.log(this.data_login.otp);  
    },
    resendOTP(){
      if (sessionStorage.getItem('resend_countdown') == null) {
        this.data_login = JSON.parse(sessionStorage.getItem('data_login'));
        const datas = {
          userId : this.data_login.user.id
        };
        // console.log(datas);
        Axios.post('api/resendOTP',datas).then(response => {
          sessionStorage.removeItem('data_login');
          this.data_login = response.data;
          sessionStorage.setItem('data_login',JSON.stringify(this.data_login))
          sessionStorage.setItem('resend_countdown', 300);
          // console.log(response);
      });
      }else{
        var countdown = parseFloat(sessionStorage.getItem('resend_countdown'));
        // this.otpMessage = "You need to wait to resend code again about "+(countdown/60)+' Minutes';
        // this.otpMessage = countdown;
      }
      this.countdown_resend();
    },
    countdown_resend(){
      var countdown  = 0;
        if (sessionStorage.getItem('counted') != 'true') {
          if (sessionStorage.getItem('resend_countdown') != null && parseInt(sessionStorage.getItem('resend_countdown')) > 0){
          var msgCoundown =  setInterval(() => {
                countdown = parseInt(sessionStorage.getItem('resend_countdown'));
                countdown = countdown - 1;
                sessionStorage.setItem('resend_countdown',countdown);
                console.log('-1');
                if (countdown <= 0 || isNaN(countdown)) {
                  clearInterval(msgCoundown);
                  sessionStorage.removeItem('resend_countdown');
                  sessionStorage.removeItem('counted');
                }
                
            }, 1000)
            // var intervalId = setInterval(function(){
            //   countdown = parseInt(sessionStorage.getItem('resend_countdown'));
            //   countdown = countdown - 1;
            //   sessionStorage.setItem('resend_countdown',countdown);
            //   // console.log('-1');
            //   this.otpMessage = countdown;
            //   if (countdown <= 0 || isNaN(countdown)) {
            //     clearInterval(intervalId)
            //   }
            // }, 1000);
          }
          sessionStorage.setItem('counted',true);
        } else{
          var msgCoundown = setInterval(()=>{
            countdown = parseInt(sessionStorage.getItem('resend_countdown'));
            //  countdown = countdown - 1;
            this.otpMessage = "You need to wait to resend code again about "+Math.floor(countdown/60)+' Minutes '+Math.round(countdown%60)+' Seconds';
          },1000);
        }
    },
    
  },
};
</script>

<style >
  .otp-input {
    width: 40px;
    height: 40px;
    padding: 5px;
    margin: 0 10px;
    font-size: 20px;
    border: 1px solid #FFF;
    text-align: center;
    color: #FFF;
    background-color: transparent;
  }

  .otp-input::-webkit-inner-spin-button,
  .otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input::placeholder {
    font-size: 15px;
    text-align: center;
    font-weight: 600;
  }
</style>
