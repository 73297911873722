<template>
  <div>
    <div v-for="(item, index) in items" :key="index" class="bg-transparent ">
      <div class="font-bold cursor-pointer flex justify-between text-lg items-center border-b-[1px] border-b-[white] font-neuton mt-4 mb-2" @click="toggleItem(index)">
        {{ item.title }}
        <i class="text-2xl" :class="['icon', { 'open': item.open }]"></i>
      </div>
      <div class="font-neuton text-lg mb-2" v-show="item.open">
        <div class=" text-[#81868B] hover:text-white cursor-pointer" v-for="(contentItem, contentIndex) in item.content" :key="contentIndex">
          <router-link :to="contentItem.link" class="hover:text-[white]" >
            {{ contentItem.title }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: Array,
  },
  data() {
    return {
    };
  },
  methods: {
    toggleItem(index) {
      this.items[index].open = !this.items[index].open;
    },
    handleClickSubMenu(menu) {
      this.$emit('selectSubMenu', menu);
    },
  },
};
</script>

<style scoped>
.icon::before {
  content: "+";
  transition: transform 0.3s;
}

.icon.open::before {
  content: "-";
  transform: rotate(180deg);
}
</style>
