<template>
  <div class="relative inline-block text-left w-full">
    <div class="at-display-form">
      <div class="relative flex items-center">
        <input :type="typeInput" :value="values" :placeholder="placeholder" @input="changeInput" class="bg-transparent border-b-[1px] border-b-white py-3 text-white placeholder:text-left placeholder:text-white placeholder:font-catarell placeholder:font-normal font-catarell w-full outline-0">
        <svg class="absolute right-0" xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 20 18" fill="none">
          <path d="M18.862 6.29808C20.3287 7.59191 20.3287 9.87869 18.862 11.1725L12.0769 17.158C11.5592 17.6147 10.7693 17.5652 10.3126 17.0475C9.8559 16.5298 9.90536 15.7399 10.4231 15.2832L16.4287 9.9853H1.25C0.559645 9.9853 0 9.42565 0 8.7353C0 8.04494 0.559645 7.4853 1.25 7.4853H16.4287L10.4231 2.18741C9.90536 1.73071 9.8559 0.940803 10.3126 0.423096C10.7693 -0.0946118 11.5592 -0.144072 12.0769 0.312623L18.862 6.29808Z" fill="white"/>
        </svg>
      </div>

      <div v-if="isOpen" @click="showModalComplete" class="fixed inset-0 h-full w-full z-10"></div>
      <div v-if="isOpen" class=" w-full opacity-80 z-10 mt-2 ring-1 ring-black ring-opacity-5">
        <div
          v-if="filteredData.length > 0"
        >
          <p class="my-2">Did you mean :</p>
          <div
            v-for="item in filteredData"
            :key="item.id"
            @click="selectedCompleteText(item.title)"
            class="py-2 flex items-center "
          >
            {{ item.title }}
          </div>
        </div>
        <div
          v-else
          class="py-2 flex items-center"
        >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: String,
    typeInput: String,
    values: String,
    dataSet: Array,
  },
  data() {
    return {
      isOpen: false,
      searchTerm: '',
    };
  },
  computed: {
    filteredData() {
      return this.dataSet.filter(item => item.title.toLowerCase().includes(this.searchTerm.toLowerCase()));
    },
  },
  methods: {
    changeInput(e) {
      const value = e.target.value;
      this.searchTerm = value;
      if(!this.isOpen) this.isOpen = !this.isOpen;
      this.$emit('changeInput', value);
    },
    showModalComplete() {
      this.isOpen = false;
    },
    selectedCompleteText(item) {
      this.$emit('changeInput', item);
      this.isOpen = false;
    }
  },
};
</script>

<style scoped></style>
