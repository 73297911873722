<template>
    <nav class="navbar navbar-expand-md fixed-top bg-white">
        <div class="container text-center">
            <p class="w-100 text-[0.6rem] md:text-[0.8rem] py-[0.2px]">
                Enjoy free shipping on orders over $100
            </p>
        </div>
    </nav>
</template>

<script>
export default {

}
</script>

<style scoped>
    .as-info {
    font-size: 1.125rem;
    line-height: 1.75rem;
    }

    @media (max-width: 768px) {
    .as-info {
        font-size: 0.5rem;
        line-height: 1.75rem;
    }
    }

</style>