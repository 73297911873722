import { createApp } from 'vue'
import App from './App.vue'
import Axios from 'axios'

import { OhVueIcon, addIcons } from "oh-vue-icons";
// import { FaFlag, RiZhihuFill } from "oh-vue-icons/icons";
import * as FaIcons from "oh-vue-icons/icons";
const fa = Object.values({ ...FaIcons });
addIcons(...fa);

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.min'
import '@/assets/css/app.css'

// Tailwind CSS
import './main.css'

import router from './router'

// Axios.defaults.baseURL = 'https://backend.asixth.com'
Axios.defaults.baseURL = 'http://127.0.0.1:8000'
Axios.defaults.headers.post['Accept'] = "application/json"
Axios.defaults.headers.post['Authorization'] = null

const app = createApp(App)
app.component("v-icon", OhVueIcon);
app.use(router)
app.mount('#app')
