<template>
    <nav class="navbar navbar-expand-md fixed-top mt-5">
        <div class="container-fluid menu-wrapper text-white">
            <a class="navbar-brand icon" href="#">
                <v-icon name="fa-bars" scale="1.6" fill="#fff"/>
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
                aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarCollapse">
                <div class="navbar-nav w-100">
                    <div class="w-100 text-center">
                        <img class="logo" src="https://img.freepik.com/free-vector/bird-colorful-gradient-design-vector_343694-2506.jpg" alt="">
                    </div>
                </div>
                <div class="d-flex">
                    <a href="" class="text-white shop-all">Shop All</a>
                    <a href="" class="mx-2">
                        <v-icon name="fa-user" scale="1.6" fill="#fff"/>
                    </a>
                    <a href="" class="mx-2">
                        <v-icon name="fa-shopping-bag" scale="1.6" fill="#fff"/>
                    </a>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped></style>